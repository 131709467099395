import { useMagicKeys } from '@vueuse/core';
import { watch } from 'vue';

import DebugPane from '@/app/modules/Debug/components/DebugPane.vue';

import { modalManager, useModalManager } from '@/shared/modals/modalManager';

declare global {
  interface Window {
    openDebugPane: () => void;
  }
}

export default function useDebugPane() {
  const drawer = useModalManager();

  // user must hold Shift+G+E+P
  const { Shift_G_E_P } = useMagicKeys();

  const openDebugPane = () => {
    drawer.open(DebugPane);
  };

  window.openDebugPane = openDebugPane;

  watch(Shift_G_E_P, triggered => {
    if (triggered && !modalManager.getCurrent()) {
      openDebugPane();
    }
  });
}
