/**
 * Takes an object with nested objects and flattens their keys such that an object without any nested objects
 * is returned.  Keys will be separated with a period.
 *
 * e.g. flattenObject({foo: { bar: 123 }});
 * {"foo.bar": 123}
 *
 * This is primarily used for Appcues to pass additional properties:
 * "Beware! Any identify call with an array or nested object as a property value will not appear in your Appcues account."
 * @see https://docs.appcues.com/dev-api-data/javascript-api-developer
 */
export default function flattenObject(source: object): object {
  return Object.assign(
    {},
    ...(function _flat(obj: object, prefix?: string): object[] {
      return Object.entries(obj).flatMap(([key, value]) => {
        const nestedKey: string = prefix ? `${prefix}.${key}` : key;
        return typeof value === 'object' && value !== null ? _flat(value, nestedKey) : { [nestedKey]: value };
      });
    })(source),
  );
}
