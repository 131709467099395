import { createFeature, currentSiteOrAnySites } from '@App/config/features';

const appFeatures = {
  sensitive: createFeature({
    enabled: ({ flags }) => flags.sensitive !== 'hide',
    config: ({ flags }) => flags.sensitive,
  }),
  browserCheck: createFeature({
    enabled: ({ flags }) => flags.browserCheck !== 'pass',
    config: ({ flags }) => flags.browserCheck,
  }),
  dts: createFeature({
    // using feature.scopes() is recommended with this feature to ensure the right
    // context is being used for the check
    enabled: currentSiteOrAnySites(site => site.deliveryTimeSlotsEnabled),
  }),
  palletManagement: createFeature({
    enabled: ({ site }) => site?.palletManagement?.enabled || false,
    config: ({ site }) => ({
      preferredTransferType: site?.palletManagement?.preferredTransferType,
      allowedPalletTypes: site?.palletManagement?.allowedPalletTypes || [],
    }),
  }),
  resolveIssues: createFeature({
    enabled: currentSiteOrAnySites(
      site =>
        site.consignmentImportConfiguration?.enabled && site.consignmentImportConfiguration?.transferPolicy !== 'never',
    ),
  }),
  consignmentImport: createFeature({
    enabled: currentSiteOrAnySites(site => site.consignmentImportConfiguration?.enabled),
    config: ({ site }) => ({
      transferPolicy: site?.consignmentImportConfiguration?.transferPolicy,
    }),
  }),
  orderConsolidation: createFeature({
    enabled: ({ site }) => !!site?.orderConsolidationEnabled,
  }),
  dangerousGoods: createFeature({
    // TODO: turn this from || to && once site data is populated
    // https://flip-eng.atlassian.net/browse/GEPPIE-8524
    // enabled: (context) => !!context.flags.dg && currentSiteOrAnySites(site => site.dangerousGoodsEnabled)(context),
    enabled: context => {
      if (context.flags.dgSite === 'enabled') return true;
      if (context.flags.dgSite === 'disabled') return false;
      return currentSiteOrAnySites(site => !!site.dangerousGoods.isTransacting)(context);
    },
    config: ({ site, flags }) => {
      const config = {
        hasTransacted: site?.dangerousGoods.hasTransacted,
      };
      if (flags.dgSitePast === 'enabled') config.hasTransacted = true;
      else if (flags.dgSitePast === 'disabled') config.hasTransacted = false;
      return config;
    },
  }),
  // note: in the future, this check must be done using authz. however, at present
  // there isn't an ergonomic way to source facts from authz, so we will get the
  // detail from org instead.
  userCanTransactWithDangerousGoods: createFeature({
    enabled: context => {
      if (context.flags.dgUser === 'enabled') return true;
      if (context.flags.dgUser === 'disabled') return false;
      return !!context.site?.userCanTransactWithDangerousGoods;
    },
  }),
  // https://flip-eng.atlassian.net/browse/GEPPIE-8785
  dangerousGoodsNotifiable: createFeature({
    enabled: ({ flags }) => !!flags.dgNotifiable,
  }),
  orderIntegration: createFeature({
    enabled: context => {
      if (context.flags.orderIntegration) return true;
      return currentSiteOrAnySites(site => !!site?.orderConfiguration?.integrationEnabled)(context);
    },
  }),
  newConsignmentForm: createFeature({
    enabled: ({ flags }) => {
      return !!flags.newConsignmentForm;
      // if (flags.newConsignmentForm) return true;
      // if (!site?.id) return false;
      // return [
      //   '6c39baa5-1160-544c-b9b7-92754b9a8484', // Organic Limbs -> General Testing
      //   'ccfa24dc-4f83-50b5-a150-9c406685b034', // Synthetic Parts -> Borg Cube
      //   // pilot site list, sourced from https://docs.google.com/spreadsheets/d/1osYMojnnzFzNo4MbkPcpplztsZZLaqg_1ecP8IfYlP0/edit?gid=145335373#gid=145335373
      //   '5c8cd430-26a5-5103-a8b8-6a06f1338a7f', // BQ Design -> Notting Hill
      //   // '74041042-92d7-57bd-ba59-2f2ecb87dbf9', // Golf Works -> Dandenong South  (re-enable once autoprint is tested)
      //   'c6ca5e86-17dd-57c7-8670-54525647ba7f', // Hufflett Rugs -> Bayswater
      //   'c001013c-71ba-5bdc-98d1-a6c19cfd7e88', // La Marzocco -> Spare Parts - Abbotsford
      //   'bfb81b53-ea53-561e-8205-c88093f6bd77', // Ontex BVBA -> Eastern Creek
      //   '8bd147ea-30e7-50f1-b6f7-3210fa2518e8', // Shoof International -> Tullamarine
      // ].includes(site.id);
    },
  }),
  debugConsignmentForm: createFeature({
    enabled: ({ flags }) => !!flags.debugConsignmentForm,
  }),
  disableDocuments: createFeature({
    enabled: ({ flags }) => !!flags.noDocuments,
  }),
};

export default appFeatures;
