<script setup lang="ts">
  // TODO: consolidate DebugPane https://flip-eng.atlassian.net/browse/GEPPES-4925
  import cloneDeep from 'lodash/cloneDeep';
  import isEqual from 'lodash/isEqual';
  import { computed, onBeforeMount, ref, toRaw } from 'vue';

  import BaseButton from '@/shared/components/BaseButton.vue';
  import ControlSegmented from '@/shared/components/controls/ControlSegmented.vue';
  import ControlSwitch from '@/shared/components/controls/ControlSwitch.vue';
  import ControlText from '@/shared/components/controls/ControlText.vue';
  import InfoTooltip from '@/shared/components/InfoTooltip.vue';
  import developmentFlags from '@/shared/developmentFlags';
  import DrawerCard from '@/shared/modals/DrawerCard.vue';
  import { useModal } from '@/shared/modals/modalManager';

  import appFlags, { AppFlag } from '@App/config/flags';
  import confirm, { ACTIONS, DIALOG_TYPE } from '@App/confirm';

  type AppFlagName = keyof typeof appFlags;
  type State = Partial<Record<AppFlagName, string | boolean | string[]>>;

  const modal = useModal();

  const state = ref<State>({});
  const defaultState = ref<State>({});
  const ogState = ref<State>({});
  const hasUnsavedChanges = computed(() => !isEqual(state.value, ogState.value));
  const hasNonDefault = computed(() => !isEqual(state.value, defaultState.value));

  const flagGroups = computed(() =>
    Object.entries(appFlags).reduce<Record<string, Record<string, AppFlag>>>((acc, [flagName, flag]) => {
      const groupName = flag.group || '';
      if (!acc[groupName]) acc[groupName] = {};
      acc[groupName][flagName] = flag;
      return acc;
    }, {}),
  );

  const checkAndClose = async () => {
    if (hasUnsavedChanges.value) {
      const action = await confirm({
        type: DIALOG_TYPE.WARNING,
        title: 'Discard changes?',
        message: 'Your unsaved changes will be lost',
        confirmLabel: 'OK',
        cancelLabel: 'Cancel',
      });
      if (action !== ACTIONS.CONFIRM) return;
    }

    modal.close();
  };

  onBeforeMount(() => {
    defaultState.value = Object.entries(appFlags).reduce(
      (acc, [flagName, flag]) => ({
        ...acc,
        [flagName]: flag.default,
      }),
      {},
    );

    const flagValues = developmentFlags.resolve({ namespace: 'sender-ui', definition: appFlags }) as Record<
      string,
      string | boolean | string[]
    >;
    state.value = Object.entries(appFlags).reduce(
      (acc, [flagName, flag]) => ({
        ...acc,
        [flagName]: cloneDeep(flagValues[flagName] ?? flag.default),
      }),
      {},
    );
    ogState.value = cloneDeep(state.value);
  });

  const getBool = (name: AppFlagName) => state.value[name] === true;
  const setBool = (name: AppFlagName, newValue: boolean) => {
    state.value[name] = newValue;
  };

  const getOptions = (name: AppFlagName) => (appFlags[name].options || []).map(o => ({ value: o, label: o }));

  const getEnum = (name: AppFlagName, isMultiple = false) => {
    const value = state.value[name];
    if (!value) return null;
    if (isMultiple) {
      return Array.isArray(value) ? value.map(v => v.toString()) : [value.toString()];
    }
    return (Array.isArray(value) ? value[0].toString() : value).toString();
  };
  const setEnum = (name: AppFlagName, newValue?: string | string[] | null, isMultiple = false) => {
    if (!newValue) state.value[name] = undefined;
    else if (isMultiple) {
      state.value[name] = Array.isArray(newValue) ? newValue : [newValue];
    } else {
      state.value[name] = Array.isArray(newValue) ? newValue[0] : newValue;
    }
  };

  const save = () => {
    // we only save flags that differ from default
    const flags: Record<string, string | string[] | boolean> = Object.entries(state.value).reduce(
      (acc, [key, value]) => (value !== defaultState.value[key] ? { ...acc, [key]: toRaw(value) } : acc),
      {},
    );
    developmentFlags.save('sender-ui', flags);
    document.location.reload();
  };

  const reset = async () => {
    const action = await confirm({
      type: DIALOG_TYPE.WARNING,
      title: 'Reset development flags?',
      message: 'This will revert all development flag to their default value',
      confirmLabel: 'Reset',
      cancelLabel: 'Cancel',
    });

    if (action !== ACTIONS.CONFIRM) return;

    state.value = cloneDeep(defaultState.value);
    save();
  };
</script>

<template>
  <DrawerCard title="Development Flags" :close="checkAndClose" class="flag-drawer">
    <section>
      <main>
        <div
          v-for="(groupFlags, group) in flagGroups"
          :key="group"
          class="flag-group"
          :class="{ 'default-group': group === '' }"
        >
          <h3>{{ group }}</h3>
          <div v-for="(flag, name) in groupFlags" :key="name" class="flag">
            <div class="flag-control">
              <div class="flag-label">
                {{ name }}
                <InfoTooltip v-if="flag.description" position="is-right">
                  {{ flag.description }}
                </InfoTooltip>
              </div>
              <div v-if="flag.type === 'boolean'" class="flag-value">
                <ControlSwitch :model-value="getBool(name)" @update:model-value="setBool(name, $event)" />
              </div>
              <div v-else-if="flag.type === 'string'" class="flag-value">
                <ControlText v-model="state[name] as string" />
              </div>
              <div v-else-if="flag.type === 'enum' || flag.type === 'enums'" class="flag-value">
                <ControlSegmented
                  :model-value="getEnum(name, flag.type === 'enums')"
                  :options="getOptions(name)"
                  :multiple="flag.type === 'enums'"
                  @update:model-value="setEnum(name, $event, flag.type === 'enums')"
                />
              </div>
              <div v-else>Unknown control type '{{ flag.type }}'</div>
            </div>
          </div>
        </div>
      </main>
    </section>
    <template #footer>
      <div class="flag-actions">
        <BaseButton :disabled="!hasNonDefault" variant="danger" @click.prevent="reset">Reset flags</BaseButton>
        <BaseButton :disabled="!hasUnsavedChanges" @click.prevent="save">Save Changes</BaseButton>
      </div>
    </template>
  </DrawerCard>
</template>

<style lang="scss" scoped>
  :global(.modal .drawer-card.flag-drawer .drawer-card-body) {
    padding: 0.5rem;
  }

  .flag-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flag-label {
    font-family: monospace;
    font-size: 14px;
  }

  .flag {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    &:not(:last-child) {
      border-bottom: 1px solid var(--chrome-50);
    }
  }

  .flag-group {
    padding: 1rem;
    padding-bottom: 0;

    &:not(.default-group) {
      @include material-thin();
      border: 1px solid var(--chrome-100);
      border-radius: 4px;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    h3 {
      margin-bottom: 1rem;
    }
  }

  .flag-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
