import { Temporal } from '@js-temporal/polyfill';
import pickBy from 'lodash/pickBy';

import { Interval } from '@/shared/DateTime';
import { ConsignmentResultSet, consignmentSummaryFactory } from '@/shared/models';
import { consignmentTrackingEventFactory } from '@/shared/models/Consignment/ConsignmentTrackingEvent';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';
import { PalletTransferType } from '@/shared/types/ConsignmentPallets';

type ClientResultSet = operations['getConsignments']['responses']['200']['content']['application/json'];

function mapConsignmentSummary(consignmentSummary: components['schemas']['SearchResultResource']['data'][number]) {
  return consignmentSummaryFactory.create({
    id: consignmentSummary.id,
    type: consignmentSummary.attributes.type,
    consignmentNo: consignmentSummary.attributes.consignmentNo,
    previousConsignmentNumbers: consignmentSummary.attributes.previousConsignmentNumbers,
    updated: consignmentSummary.attributes.updated,
    dispatchDate: consignmentSummary.attributes.dispatchDate,
    sender: { ...consignmentSummary.attributes.sender, address: consignmentSummary.attributes.sender.address },
    receiver: {
      address: consignmentSummary.attributes.receiver.address,
      name: consignmentSummary.attributes.receiver.name,
      deliveryReferences: consignmentSummary.attributes.receiver.deliveryTimeSlot?.references || [],
      deliveryTimeSlot: consignmentSummary.attributes.receiver.deliveryTimeSlot
        ? {
            window: Interval.from(consignmentSummary.attributes.receiver.deliveryTimeSlot.slot.window),
            recurrences: consignmentSummary.attributes.receiver.deliveryTimeSlot?.slot.recurrences || 0,
          }
        : undefined,
      specialInstructions: consignmentSummary.attributes.receiver.specialInstructions,
    },
    tracking: consignmentSummary.attributes.tracking?.map(consignmentTrackingEventFactory.create) || [],
    agreedService: consignmentSummary.attributes.agreedService,
    carrier: consignmentSummary.attributes.carrier,
    manifest: consignmentSummary.attributes.manifest
      ? {
          ...consignmentSummary.attributes.manifest,
          addedAt: Temporal.Instant.from(consignmentSummary.attributes.manifest.addedAt),
        }
      : undefined,
    transfer: consignmentSummary.attributes.transfer
      ? {
          ...consignmentSummary.attributes.transfer,
          transferredAt: consignmentSummary.attributes.transfer?.transferredAt
            ? Temporal.Instant.from(consignmentSummary.attributes.transfer?.transferredAt)
            : undefined,
        }
      : undefined,
    transferPolicy: consignmentSummary.attributes.transferPolicy,
    estimatedTimeOfArrival: consignmentSummary.attributes.estimatedTimeOfArrival,
    references: consignmentSummary.attributes.references || [],
    itemQuantity: consignmentSummary.attributes.itemQuantity,
    volume: consignmentSummary.attributes.volume,
    weight: consignmentSummary.attributes.weight,
    organisation: consignmentSummary.attributes.organisation,
    site: consignmentSummary.attributes.site,
    status: consignmentSummary.attributes.status,
    pallets: consignmentSummary.attributes.pallets
      ? {
          transferType: consignmentSummary.attributes.pallets.transferType as PalletTransferType,
          quantity: consignmentSummary.attributes.pallets.quantity,
          docketNumbers: consignmentSummary.attributes.pallets.docketNumbers,
        }
      : undefined,
    hasDangerousGoods: consignmentSummary.attributes.hasDangerousGoods,
    issues: consignmentSummary.attributes.issues
      ? pickBy(consignmentSummary.attributes.issues, v => v !== undefined)
      : undefined,
    pickupRequests:
      consignmentSummary.attributes.pickupRequests?.map(r => ({
        requestId: r.requestId,
        pickupId: r.pickupId,
        status: r.status,
        createdAt: Temporal.Instant.from(r.createdAt),
        updatedAt: Temporal.Instant.from(r.updatedAt),
      })) || [],
  });
}

export const mapClientResultSetToResultSet = ({ data, meta }: ClientResultSet) =>
  ConsignmentResultSet.create({
    consignments: data.map(conSummary => (conSummary ? mapConsignmentSummary(conSummary) : undefined)),
    total: meta.page.totalItems,
    numPages: meta.page.itemsPerPage ? Math.ceil(meta.page.totalItems / meta.page.itemsPerPage) : -1,
  });
