// todo: remove CFR: namespace when we go live

export enum ConsignmentInstrumentation {
  CONSIGNMENT_CREATED = 'CFR: consignment created',
  CONSIGNMENT_DETAILS_EXPANDED = 'CFR: consignment details expanded',
  /* Service Selection */
  QUOTES_REQUESTED = 'CFR: quotes requested',
  QUOTE_SELECTION_FINALISED = 'CFR: quote selection finalised',
  QUOTE_SELECTED = 'CFR: quote selected',

  ENQUIRY_STARTED = 'CFR: enquiry started',
  ENQUIRY_CREATED = 'CFR: enquiry created',

  CONSIGNMENT_UPDATED = 'CFR: consignment updated',

  ADDRESS_MODE_CHANGE = 'CFR: address mode change',

  CONSIGNMENT_AUTOPRINT_LABEL_COPIES_UPDATED = 'CFR: consignment autoprint label copies updated',

  /* consolidation */
  /* OC site feature not enabled but suggestions available */
  CONSIGNMENT_CONSOLIDATION_OPPORTUNITY_MISSED = 'CFR: consignment consolidation opportunity missed',
  CONSIGNMENT_CONSOLIDATION_SUGGESTIONS_PRESENTED = 'CFR: consignment consolidation suggestions presented',
  CONSIGNMENT_CONSOLIDATION_OPPORTUNITY_REJECTED = 'CFR: consignment consolidation opportunity rejected',
  CONSIGNMENT_CONSOLIDATION_DISCARDED = 'CFR: consignment consolidation discarded',
  CONSIGNMENT_PRESELECTED_SERVICE_QUOTE_UPDATED = 'CFR: consignment preselected service quote updated',
}

export default ConsignmentInstrumentation;
