/**
 * cm to mm conversion with null return
 * @param val
 * @returns {number|null}
 */
export const cm2mm = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) * 10 : null;

/**
 * mm to cm conversion with null return
 * @param val
 * @returns {number|null}
 */
export const mm2cm = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) / 10 : null;

/**
 * mm to m conversion with null return
 * @param val
 * @returns {number|null}
 */
export const mm2m = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) / 1000 : null;

/**
 * m to mm conversion with null return
 * @param val
 * @returns {number|null}
 */
export const m2mm = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) * 1000 : null;

/**
 * mm^3 to m^3 conversion with null return
 * @param val
 * @returns {number|null}
 */
export const mm2m3 = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) / 1e9 : null;

/**
 * m^3 to mm^3 conversion with null return
 * @param val
 * @returns {number|null}
 */
export const m2mm3 = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) * 1e9 : null;

/**
 * kg to gr conversion with null return
 * @param val
 * @returns {number|null}
 */
export const kg2gr = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) * 1000 : null;

/**
 * gr to kg conversion with null return
 * @param val
 * @returns {number|null}
 */
export const gr2kg = (val?: number | string | null) =>
  val || val === 0 ? Number.parseFloat(val.toString()) / 1000 : null;
