import { Temporal } from '@js-temporal/polyfill';

import { toISODate } from '@/shared/DateTime/formatters';
import type {
  Address,
  Estimate,
  EstimateConvertedConsignment,
  EstimateLineItem,
  EstimateReceiver,
  EstimateSender,
  EstimateUpdated,
  LocalityWithTimeZone,
  NewEstimate,
  NewEstimateLineItem,
  NewEstimateLocation,
  NewEstimateReceiver,
  NewEstimateSender,
  Quote,
} from '@/shared/models';
import {
  createAddress,
  estimateAddresseeFactory,
  estimateFactory,
  lineItemFactory,
  localityFactory,
} from '@/shared/models';

import { PaginatedItems } from '@/shared/models/helpers/PaginatedItems';
import { removeEmptyEvaluations } from '@/shared/models/Quote/helpers';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/estimates.schema';

const mapEstimateAddressToAddress = (
  response: components['schemas']['Address'] | components['schemas']['LocalityWithTimeZone'],
): Address | LocalityWithTimeZone =>
  'id' in response ? createAddress(response) : localityFactory.createWithTimeZone(response);

const mapEstimateResponseSenderToEstimateSender = (response: components['schemas']['Sender']): EstimateSender =>
  estimateAddresseeFactory.createEstimateSender({
    name: response.name,
    address: mapEstimateAddressToAddress(response.address),
    contact: response.contact,
    residential: response.residential,
    addressBookContactId: response.addressBookContactId,
    addressBookEntryId: response.addressBookEntryId,
  });

const mapEstimateResponseLineItemToEstimateLineItem = (response: components['schemas']['LineItem']): EstimateLineItem =>
  lineItemFactory.create({
    packagingType: response.packagingType,
    shippingItemPresetId: response.shippingItemPresetId,
    description: response.description,
    quantity: response.quantity,
    length: response.length,
    width: response.width,
    height: response.height,
    weight: response.weight,
  });

const mapEstimateResponseReceiverToEstimateReceiver = (response: components['schemas']['Receiver']): EstimateReceiver =>
  estimateAddresseeFactory.createEstimateReceiver({
    name: response.name,
    address: mapEstimateAddressToAddress(response.address),
    contact: response.contact,
    deliveryTimeslotRequired: response.deliveryTimeSlotRequired,
    residential: response.residential,
    addressBookContactId: response.addressBookContactId,
    addressBookEntryId: response.addressBookEntryId,
    authorityToLeave: response.authorityToLeave,
  });

const mapEstimateResponseQuoteToQuote = (quote: components['schemas']['Quote']): Quote => ({
  id: quote.id,
  agreedServiceId: quote.agreedServiceId,
  carrierId: quote.carrierId,
  carrierServiceId: quote.carrierServiceId,
  quoteSetId: quote.quoteSetId,
  eta: quote.etaDate,
  // TODO: should we move modelling to a Cost object?
  currency: quote.cost?.currency,
  chargesBreakdown: quote.cost?.chargesBreakdown,
  fees: quote.cost?.charges,
  freight: quote.cost?.freight,
  net: quote.cost?.net,
  tax: quote.cost?.tax,
  total: quote.cost?.total,
  evaluation: quote.evaluation,
  createdAt: quote.createdAt,
  selectable: quote.isSelectable,
  recommended: quote.isSelectable && !Object.keys(removeEmptyEvaluations(quote.evaluation)).length,
});

const mapEstimateConvertedConsignmentResponseToEstimateConvertedConsignment = (
  convertedConsignment: components['schemas']['ConvertedConsignment'],
): EstimateConvertedConsignment => ({
  id: convertedConsignment.id,
  connoteNumber: convertedConsignment.connoteNumber,
  convertedAt: Temporal.Instant.from(convertedConsignment.convertedAt),
});

export const mapEstimateFetchResponseToEstimate = (response: components['schemas']['Estimate']): Estimate =>
  estimateFactory.create({
    id: response.id,
    siteId: response.siteId,
    type: response.type,
    createdAt: Temporal.Instant.from(response.createdAt),
    deletedAt: response.deletedAt ? Temporal.Instant.from(response.deletedAt) : undefined,
    updatedAt: Temporal.Instant.from(response.updatedAt),
    dispatchDate: Temporal.PlainDate.from(response.dispatchDate),
    estimateNumber: response.estimateNumber,
    references: response.references,
    sender: mapEstimateResponseSenderToEstimateSender(response.sender),
    receiver: mapEstimateResponseReceiverToEstimateReceiver(response.receiver),
    lineItems: response.lineItems.map(mapEstimateResponseLineItemToEstimateLineItem),
    quotes: response.quotes?.map(mapEstimateResponseQuoteToQuote) || [],
    selectedQuoteIds: response.selectedQuoteIds || [],
    convertedConsignment: response.convertedConsignment
      ? response.convertedConsignment.map(mapEstimateConvertedConsignmentResponseToEstimateConvertedConsignment)
      : undefined,
    hasDangerousGoods: response.hasDangerousGoods,
  });

const mapEstimateLocationToEstimateRequestLocation = (
  location: NewEstimateLocation,
): components['schemas']['UpdateAddress'] | components['schemas']['Locality'] => {
  if (location.address && 'id' in location.address) {
    if (!location.address.id) throw new MappingError('Estimate cannot be created without an address id');
    return {
      id: location.address.id,
      line2: location.address.line2,
    };
  }
  if (location.locality) {
    return location.locality;
  }
  throw new MappingError('Estimate cannot be created without a sender location');
};

const mapNewEstimateSenderToEstimateRequestSender = (
  addressee: NewEstimateSender,
): components['schemas']['CreateEstimate']['sender'] => ({
  name: addressee.name,
  address: mapEstimateLocationToEstimateRequestLocation(addressee.location),
  residential: addressee.residential,
  contact: {
    name: addressee.contact?.name,
    phone: addressee.contact?.phone,
    email: addressee.contact?.email,
  },
  addressBookEntryId: addressee.addressBookEntryId,
  addressBookContactId: addressee.addressBookContactId,
});

const mapNewEstimateReceiverToEstimateRequestReceiver = (
  addressee: NewEstimateReceiver,
): components['schemas']['CreateEstimate']['receiver'] => ({
  name: addressee.name,
  address: mapEstimateLocationToEstimateRequestLocation(addressee.location),
  residential: addressee.residential,
  contact: {
    name: addressee.contact?.name,
    phone: addressee.contact?.phone,
    email: addressee.contact?.email,
  },
  addressBookEntryId: addressee.addressBookEntryId,
  addressBookContactId: addressee.addressBookContactId,
  authorityToLeave: addressee.authorityToLeave,
  deliveryTimeSlotRequired: addressee.deliveryTimeslotRequired,
});

const mapEstimateLineItemToEstimateRequestLineItem = (
  lineItem: NewEstimateLineItem,
): components['schemas']['CreateEstimate']['lineItems'][number] => {
  if (!lineItem.packagingType) throw new MappingError('Estimate line item cannot be created without packagingType');
  if (!lineItem.quantity) throw new MappingError('Estimate line item cannot be created without quantity');
  if (!lineItem.length) throw new MappingError('Estimate line item cannot be created without length');
  if (!lineItem.width) throw new MappingError('Estimate line item cannot be created without width');
  if (!lineItem.height) throw new MappingError('Estimate line item cannot be created without height');
  if (!lineItem.weight) throw new MappingError('Estimate line item cannot be created without weight');

  return {
    description: lineItem.description,
    packagingType: lineItem.packagingType,
    shippingItemPresetId: lineItem.shippingItemPresetId,
    quantity: Math.round(lineItem.quantity),
    length: Math.round(lineItem.length),
    width: Math.round(lineItem.width),
    height: Math.round(lineItem.height),
    weight: Math.round(lineItem.weight),
  };
};

export const mapEstimateToEstimateCreateRequest = (estimate: NewEstimate): components['schemas']['CreateEstimate'] => {
  if (!estimate.siteId) throw new MappingError('Estimate cannot be created without a siteId');
  if (!estimate.dispatchDate) throw new MappingError('Estimate cannot be created without a dispatchDate');
  if (!estimate.type) throw new MappingError('Estimate cannot be created without a type');
  if (!estimate.sender) throw new MappingError('Estimate cannot be created without a sender');
  if (!estimate.receiver) throw new MappingError('Estimate cannot be created without a receiver');
  if (!estimate.lineItems) throw new MappingError('Estimate cannot be created without lineItems');

  return {
    siteId: estimate.siteId,
    type: estimate.type,
    dispatchDate: toISODate(estimate.dispatchDate),
    sender: mapNewEstimateSenderToEstimateRequestSender(estimate.sender),
    receiver: mapNewEstimateReceiverToEstimateRequestReceiver(estimate.receiver),
    lineItems: estimate.lineItems.map(mapEstimateLineItemToEstimateRequestLineItem),
    references: estimate.references,
    hasDangerousGoods: estimate.hasDangerousGoods,
  };
};

export const mapEstimateResponseToEstimateUpdated = (
  estimateUpdated: components['schemas']['EstimateUpdated'],
): EstimateUpdated => ({
  id: estimateUpdated.id,
  quotes: estimateUpdated.quotes.map(mapEstimateResponseQuoteToQuote),
});

export const mapEstimateSearchResponseToEstimateResultSet = ({
  data,
  meta,
}: operations['getEstimates']['responses']['200']['content']['application/json']): PaginatedItems<Estimate> => ({
  items: data.map(mapEstimateFetchResponseToEstimate),
  offset: meta.page.startIndex,
  pageSize: meta.page.itemsPerPage,
  total: meta.page.totalItems,
  numPages: Math.ceil(meta.page.totalItems / meta.page.itemsPerPage),
});
