/**
 * Defines the contact information for an address book entry.
 */
interface Contact {
  // Address book contact id.
  id?: string;
  name: string;
  phone: string;
  email?: string;
}

const newContact = (): Contact => ({
  name: '',
  phone: '',
});

export const createContact = ({ id, name, phone, email }: Contact | undefined = newContact()): Contact => ({
  id,
  name,
  phone,
  email,
});

// TODO refactor to factory function and use full view model for mapping instead of this partial random definition.
/** @deprecated */
interface ContactFromAddress {
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
}

export const createContactFromAddressObject = ({
  contactName = '',
  contactPhone = '',
  contactEmail = '',
}: ContactFromAddress) =>
  createContact({
    name: contactName,
    phone: contactPhone,
    email: contactEmail,
  });

export default Contact;
