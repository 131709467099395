import { ConsignmentAddressee, ConsignmentConsolidationSuggestion, NewConsignment } from '@/shared/models';
import { ConsignmentConsolidationItemSummary } from '@/shared/models/Consignment/ConsignmentConsolidationSuggestion';
import MappingError from '@/shared/services/errors/MappingError';
import { components, operations } from '@/shared/services/schema/geppetto-sender-app/consignments.schema';
import ConsignmentFormData from '@/shared/models/Consignment/ConsignmentFormData';
import { AddresseeFormData } from '@/shared/models/AddresseeFormData';

const ConsignmentAddresseeToConsolidationAddress = (addressee: ConsignmentAddressee) => ({
  name: addressee.name,
  address: {
    ...addressee.address!,
    line2: addressee.line2,
  },
});

type CreateConsolidationRequest =
  operations['getConsolidationCandidates']['requestBody']['content']['application/json'];

export const consignmentToConsolidationRequest = (consignment: NewConsignment): CreateConsolidationRequest => {
  if (!consignment.siteId) throw new MappingError('siteId is required');
  if (!consignment.dispatchDate) throw new MappingError('dispatchDate is required');
  if (!consignment.sender) throw new MappingError('sender is required');
  if (!consignment.receiver) throw new MappingError('receiver is required');

  return {
    data: {
      siteId: consignment.siteId,
      dispatchDate: consignment.dispatchDate,
      sender: ConsignmentAddresseeToConsolidationAddress(consignment.sender),
      receiver: ConsignmentAddresseeToConsolidationAddress(consignment.receiver),
    },
  };
};

const mapAddressToConsolidationRequestAddress = (
  address: AddresseeFormData['location']['address'],
): components['schemas']['ConsolidationAddress'] => {
  if (!address) throw new MappingError('address is required');
  if (!address.countryId) throw new MappingError('address.countryId is required');
  if (!address.subdivision) throw new MappingError('address.subdivision is required');
  if (!address.locality) throw new MappingError('address.locality is required');
  if (!address.postcode) throw new MappingError('address.postcode is required');
  if (!address.line1) throw new MappingError('address.line1 is required');

  return {
    countryId: address.countryId,
    subdivision: address.subdivision,
    locality: address.locality,
    postcode: address.postcode,
    line1: address.line1,
    line2: address.line2,
  };
};

export const consignmentToConsolidationRequest_CFR = (
  consignment: ConsignmentFormData,
  meta: { siteId: string },
): CreateConsolidationRequest => {
  if (!meta.siteId) throw new MappingError('siteId is required');
  if (!consignment.dispatchDate) throw new MappingError('dispatchDate is required');
  if (!consignment.sender) throw new MappingError('sender is required');
  if (!consignment.sender.location.name) throw new MappingError('receiver is required');
  if (!consignment.receiver) throw new MappingError('receiver is required');
  if (!consignment.receiver.location.name) throw new MappingError('receiver is required');

  return {
    data: {
      siteId: meta.siteId,
      dispatchDate: consignment.dispatchDate.toString(),
      sender: {
        name: consignment.sender.location.name,
        address: mapAddressToConsolidationRequestAddress(consignment.sender.location.address),
      },
      receiver: {
        name: consignment.receiver.location.name,
        address: mapAddressToConsolidationRequestAddress(consignment.receiver.location.address),
      },
    },
  };
};

function candidateItemSummaryToConsignmentConsolidationItemSummary(
  summary: components['schemas']['ConsolidationCandidatesResult']['data'][number]['itemSummaries'][number],
): ConsignmentConsolidationItemSummary {
  return {
    packagingType: summary.packagingType,
    width: summary.width,
    length: summary.length,
    height: summary.height,
    weight: summary.weight,
    quantity: summary.quantity,
  };
}

export const clientConsolidationCandidatesToConsolidationSuggestions = (
  candidates: components['schemas']['ConsolidationCandidatesResult'],
): ConsignmentConsolidationSuggestion[] =>
  candidates.data.map(candidate => ({
    id: candidate.id,
    consignmentNo: candidate.consignmentNo,
    isSenderPaying: candidate.isSenderPaying,
    dispatchDate: candidate.dispatchDate,
    service: candidate.service,
    items: candidate.itemSummaries.map(candidateItemSummaryToConsignmentConsolidationItemSummary),
    eta: candidate.estimatedTimeOfArrival,
    cost: candidate.cost,
    hasDangerousGoods: candidate.hasDangerousGoods,
  }));
